import clsx from "clsx";
import type { ReactNode } from "react";
import { Checkbox, type CheckboxProps } from "./Checkbox.component";
type CheckboxWithLabelProps = CheckboxProps & {
  readonly children?: ReactNode;
};
export function CheckboxWithLabel({
  className,
  children,
  id,
  isDisabled = false,
  ...rest
}: CheckboxWithLabelProps) {
  return <span className="flex gap-x-1" data-sentry-component="CheckboxWithLabel" data-sentry-source-file="CheckboxWithLabel.component.tsx">
      <Checkbox id={id} isDisabled={isDisabled}
    // eslint-disable-next-line react/jsx-props-no-spreading -- Props are spread to the Checkbox component
    {...rest} className="peer" data-sentry-element="Checkbox" data-sentry-source-file="CheckboxWithLabel.component.tsx" />
      <label className={clsx("transition-all motion-reduce:transition-none", "text-lg peer-required:after:inline-block peer-required:after:align-text-top peer-required:after:text-sm peer-required:after:leading-none peer-required:after:text-error-400 peer-required:after:content-['*']", isDisabled ? "text-nobel" : "cursor-pointer text-charcoal", className)} htmlFor={id}>
        {children}
      </label>
    </span>;
}