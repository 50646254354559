"use client";

import clsx from "clsx";
import type { ChangeEventHandler } from "react";
import { CheckboxWithLabel } from "@/components/Checkbox/CheckboxWithLabel.component";
import { Link } from "@/components/Link/Link.component";
import { Translation } from "@/components/Translation/Translation.component";
import { useSite } from "@/contexts/site/site.context";
import { inlineJsx } from "@/ts/locale/inline-jsx";
type NewsletterSignUpPermissionCheckboxProps = {
  readonly brandName: string;
  readonly brandLink: string;
  readonly className?: string | null;
  readonly cookieAndPrivacyPolicyLink: string;
  readonly isRequired?: boolean;
  readonly isDisabled?: boolean;
  readonly name?: string;
  readonly onChange: ChangeEventHandler<HTMLInputElement>;
};
export function NewsletterSignUpPermissionCheckbox({
  brandName,
  brandLink,
  className,
  cookieAndPrivacyPolicyLink,
  isRequired,
  isDisabled,
  onChange
}: NewsletterSignUpPermissionCheckboxProps) {
  const {
    locale
  } = useSite();
  const brand = <Link className={clsx("text-primary hover:underline", className)} href={brandLink} rel="noreferrer" openInNewTab>
      {brandName}
    </Link>;
  const cookieAndPrivacyLink = <Link className="text-primary hover:underline" href={cookieAndPrivacyPolicyLink} position="newsletter-sign-up-terms" rel="noreferrer" openInNewTab>
      <Translation da="privatlivspolitik og cookiepolitik" de="Datenschutzrichtlinie und Cookie-Richtlinie" en="privacy policy and cookie policy" no="personvernerklæring og cookiepolicy" sv="integritetspolicy och cookiepolicy" />
    </Link>;
  return <CheckboxWithLabel className="text-xs" isDisabled={isDisabled} isRequired={isRequired} name="newsletterAcceptTerms" onChange={onChange} data-sentry-element="CheckboxWithLabel" data-sentry-component="NewsletterSignUpPermissionCheckbox" data-sentry-source-file="NewsletterSignUpPermissionCheckbox.component.tsx">
      {{
      da: inlineJsx`Jeg ønsker at modtage tilpassede nyhedsbreve på e-mail fra ${brand} herunder information om konferencer, kurser, netværk, konkurrencer og digitale tjenester.`,
      de: inlineJsx`Ich möchte personalisierte E-Mail-Newsletter von ${brand}, einschließlich Informationen über Konferenzen, Kurse, Networking, Wettbewerbe und digitale Dienste.`,
      en: inlineJsx`I want to receive customized email newsletters from ${brand}, including information about conferences, courses, networking, competitions and digital services. `,
      no: inlineJsx`Jeg ønsker å motta persontilpassede nyhetsbrev på e-post fra ${brand}, inkludert informasjon om konferanser, kurs, nettverksbygging, konkurranser og digitale tjenester.`,
      sv: inlineJsx`Jag vill få personligt anpassade nyhetsbrev via e-post från ${brand} inklusive information om konferenser, kurser, nätverkande, tävlingar och digitala tjänster.`
    }[locale]}
      <br />
      <br />
      {{
      da: inlineJsx`Med mit samtykke accepterer jeg også behandling af mine persondata, og at der indsamles information om min interaktion med markedsføringsmeddelelser (såsom åbning og læsning) ved hjælp af tracking pixels og lignende teknologier. Læs mere i vores ${cookieAndPrivacyLink}. Samtykket kan altid tilbagekaldes via afmeldingslink i nyhedsbreve.`,
      de: inlineJsx`Mit meiner Zustimmung erkläre ich mich auch mit der Verarbeitung meiner personenbezogenen Daten einverstanden und damit, dass Informationen über meine Interaktion mit Marketingnachrichten (wie z. B. das Öffnen und Lesen) mit Hilfe von Tracking-Pixeln und ähnlichen Technologien erfasst werden. Weitere Informationen finden Sie in unserer ${cookieAndPrivacyLink}. Die Einwilligung kann jederzeit über den Abmeldelink in Newslettern widerrufen werden.`,
      en: inlineJsx`With my consent, I also agree to the processing of my personal data and that information about my interaction with marketing messages (such as opening and reading) is collected using tracking pixels and similar technologies. Read more in our ${cookieAndPrivacyLink}. Consent can always be revoked via the unsubscribe link in newsletters.`,
      no: inlineJsx`Med mitt samtykke samtykker jeg også til behandling av mine personopplysninger og at informasjon om min interaksjon med markedsføringsmeldinger (som åpning og lesing) samles inn ved hjelp av sporingspiksler og lignende teknologier. Les mer i vår ${cookieAndPrivacyLink}. Samtykket kan alltid trekkes tilbake via avmeldingslenken i nyhetsbrev.`,
      sv: inlineJsx`Mitt samtycke innebär även att jag godkänner att mina personuppgifter behandlas och att information om min interaktion med marknadsföringsmeddelanden (såsom öppning och läsning) samlas in med hjälp av spårningspixlar och liknande teknik. Läs mer i vår ${cookieAndPrivacyLink}. Samtycket kan alltid återkallas via avregistreringslänken i nyhetsbreven.`
    }[locale]}
    </CheckboxWithLabel>;
}